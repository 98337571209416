import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { IscontrolComponent } from '../iscontrol.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ic-builder-isupload',
  templateUrl: './isupload.component.html',
  styleUrls: ['./isupload.component.scss'],
})
export class IsuploadComponent extends IscontrolComponent implements OnInit, AfterViewInit {
  constructor(
    public el: ElementRef,
    public store: Store,
    public comp: ComponentsService,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  @Input() icon: string;
  @Input() accept = '*';
  @Input() showName: boolean = false; 

  onupload: Function = null;

  reader: FileReader = new FileReader();

  formControlName: FormControl | null = null;
  formControlContent: FormControl | null = null;

  ngOnInit(): void {
    const controlNameId = super.initControl(this.id.toString() + '.name', null, 'name', null, false);
    this.formControlName = super.getFormControl(controlNameId);

    const controlContentId = super.initControl(this.id.toString() + '.content', null, 'content', null, false);
    this.formControlContent = super.getFormControl(controlContentId);
  }

  ngAfterViewInit() {
    // this.el.nativeElement.classList.add('material-icons');
  }

  getImg(ev) {
    const files = ev.target.files;
    const file = files[0];

    this.formControlName.setValue(null);
    this.formControlContent.setValue(null);

    this.reader.onload = (e) => {  
      this.formControlName.setValue(file.name);
      this.formControlContent.setValue(e.target.result);
      
      this.cd.detectChanges();

      if (this.onupload) {
        this.onupload(e.target.result, file.name);
      }
    };
    this.reader.readAsDataURL(file);
  }
}
