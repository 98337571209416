import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { LoginResponse, LoginTest } from './login.model';
import { ConfigService } from './config.service';
import { Store } from '@ngxs/store';
import { ConnectWebSocket, DisconnectWebSocket } from '@ngxs/websocket-plugin';
import { SetISSESSIONID } from './iscloud.actions';

export interface Message {
  command: string;
  params: string;
}

@Injectable()
export class IsCloudService {
  private handleError: HandleError;

  // private issession: string;
  public credentials: boolean | undefined;
  private role: number;
  public messages: Subject<Message> | undefined;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    httpErrorHandler: HttpErrorHandler,
    //private cookieService: CookieService,
    private store: Store
  ) {
    this.handleError = httpErrorHandler.createHandleError('IntercleanCloud');
    this.role = 1;

    if (document.location.host === 'localhost:4200') {
      // this.issession = 'DEBUG_IC';
    }
  }

  start(application: string): Observable<any> {
    this.credentials = true;
    console.log('start ');
    const data = new URLSearchParams();
    data.append('application', application);

    const params = { name: 'getloginform', action: 'exec' };
    const s = JSON.stringify(params);

    const url = this.config.getDbUrl('')

    return this.http
      .post<unknown>(url + '/execute.icjson', s, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: this.credentials,
      });
  }

  createwebsocket({ deviceid, accountid, callback }: any = {}) {
    if (deviceid && accountid) {
      this.store.dispatch(
        new ConnectWebSocket({ url: `${this.config.getDbUrl('ws')}?deviceid=${deviceid}&accountid=${accountid}` })
      );
    } else {
      this.store.dispatch(new ConnectWebSocket({ url: this.config.getDbUrl('ws') }));
    }
  }

  getwebsocketurl() {
    return this.config.getDbUrl('ws');
  }

  getToken() {
    return localStorage.getItem('access_token');
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }

  doLogout() {
    let removeToken = localStorage.removeItem('access_token');
    if (removeToken == null) {
      //this.router.navigate(['log-in']);
    }
  }

  login(application: string, customer: string, username: string, password: string, language: string='nl'): Observable<LoginResponse> {
    this.credentials = true;
    console.log('login');
    const data = new URLSearchParams();
    data.append('application', application);
    data.append('username', username);
    data.append('password', password);
    data.append('language',language);
    if (customer !== '' && customer) {
      data.append('customercode', customer);
    } else {
      data.append('kind', '5');
    }
    //data.append('debug', 'debug');
    //
    data.append('return', 'angular');
    const body = data.toString();

    //const token = localStorage.getItem('token');
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    // if (token){
    //   headers.set('authorization',token);
    // }
    //let headers
    //if (token){
    //  headers = new HttpHeaders({ authorization:token,'Content-Type':'application/x-www-form-urlencoded; charset=utf-8' });
    //} else 
   // {
   //   headers = new HttpHeaders({ 'Content-Type':'application/x-www-form-urlencoded; charset=utf-8' });
   // }

    const response = this.http.post<LoginResponse>(this.config.getDbUrl('') + '/login', body, {
      headers: new HttpHeaders({ 'Content-Type':'application/x-www-form-urlencoded; charset=utf-8' }),
      observe: 'response',
      withCredentials: this.credentials,
    });

    
    

    console.log(response);

    return response.pipe(
      map((item) => {
        try {
          console.log(item);
          const issession = item.body.ISSESSION;
          if (issession != null) {
            localStorage.setItem('currentUser', JSON.stringify(item.body));
            localStorage.setItem('ISSESSION', JSON.parse(localStorage.getItem('currentUser')).ISSESSION);
            //const token = item.headers.get('authorization');
            //if (token) {
            //  localStorage.setItem('token',token);
            //}

            // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
            this.credentials = true;
            //console.log(JSON.parse(localStorage.getItem('currentUser')).ISSESSION);
            //console.log(this.issession);
            this.store.dispatch({ type: '[SetFormConnectionid]' });
            //this.store.dispatch(new SetISSESSIONID(issession));
            //this.store.dispatch(new ConnectWebSocket({ url: this.config.getDbUrl('ws') }));
            return <LoginResponse>item.body;
          } else {
            return <LoginResponse>(<unknown>{
              customerid: null,
              customernr: null,
              username: null,
              customername: null,
              firstname: null,
              ISSESSION: null,
              applicationname: null,
              applicationid: null,
              language: null,
            });
          }
        } catch {
          return <LoginResponse>(<unknown>{
            customerid: null,
            customernr: null,
            username: null,
            customername: null,
            firstname: null,
            ISSESSION: null,
            applicationname: null,
            applicationid: null,
            language: null,
          });
        }
      }),
      catchError((error, caught) => {        
        return throwError(this.generalErrorHandler(error, caught)) 
      })      
    )

  }

  generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
    //this.store.dispatch({type: '[LoginFailed]',payload:{error:error.message,status:error.status}});
    return error;
  }

  restlogin(application: string, customer: string, username: string, key: string): Observable<LoginTest> {
    this.credentials = true;
    console.log('restlogin');
    const data = new URLSearchParams();
    data.append('application', application);
    data.append('username', username);
    data.append('key', key);
    if (customer !== '') {
      data.append('customercode', customer);
    }
    data.append('kind', '10');
    //
    const body = data.toString();

    return this.http
      .post<LoginTest>(this.config.getDbUrl('') + '/login', body, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Accept', 'application/json'),
        observe: 'response',
        withCredentials: this.credentials,
      })
      .pipe(
        map((response) => {
          console.log(response.headers);
          localStorage.setItem('ISSESSION', response.headers.get('ISSESSION'));

          // this.issession = response.headers.get('ISSESSION');
          this.credentials = true;

          // this.store.dispatch(new SetISSESSIONID(this.issession));
          return <LoginTest>response.body;
        })
      );
  }

  locallogin(application: string, customer: string, username: string, password: string) {
    localStorage.setItem('currentUser', 'local');
    localStorage.setItem('ISSESSION', 'public');
    // this.issession = 'public';
    this.credentials = false;
  }

  logout(): Observable<void> {
    console.log('logout');
    this.store.dispatch(new DisconnectWebSocket());
    localStorage.removeItem('currentUser');
    localStorage.removeItem('ISSESSION');
    return this.http.get<void>(`${this.config.getDbUrl('')}/logout`);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getData(remotesetname: unknown, extraparam: Object): Observable<any[]> {
    console.log('getdata ',remotesetname);
    let params = { name: remotesetname, action: 'read' };

    if (extraparam !== null) {
      params = Object.assign({}, params, extraparam);
    }

    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser'))?.ISSESSION;
    }

    //const s = encodeURIComponent(JSON.stringify(params));
    const s = JSON.stringify(params);

    return this.http.post<unknown[]>(this.config.getDbUrl('') + '/read.icjson', s, {
      headers: new HttpHeaders({
        // ISSESSION: this.issession ?? 'createsession',
      }).set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    });
  }

  getDatasets(params: Object): Observable<any[]> {
    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser'))?.ISSESSION;
    }

    //const s = encodeURIComponent(JSON.stringify(params));
    const s = JSON.stringify(params);

    return this.http.post<unknown[]>(this.config.getDbUrl('') + '/getDataset.icjson', s, {
      headers: new HttpHeaders({
        // ISSESSION: this.issession ?? 'createsession',
      }).set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    });
  }

  async getDatasync(remotesetname: unknown, extraparam?: unknown): Promise<any[]> {
    let params = { name: remotesetname, action: 'read' };

    if (extraparam !== null) {
      params = Object.assign({}, params, extraparam);
    }

    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    }

    const s = encodeURIComponent(JSON.stringify(params));

    const d = await this.http
      .post<unknown[]>(this.config.getDbUrl('') + '/read.icjson', 'Payload=' + s, {
        headers: new HttpHeaders({
          // ISSESSION: this.issession,
        }).set('Content-Type', 'application/json'),
        withCredentials: this.credentials,
      })
      .toPromise();
    return d;
  }

  execute<T = any>(remotesetname: any, extraparam: Object, data?: any): Observable<Array<T>> {
    console.log(`%cClass: IsCloudService, Function: execute(extraparam): `, 'color: black;', extraparam);
    let params = { name: remotesetname, action: 'exec' };
    if (extraparam !== null) {
      params = Object.assign({}, params, extraparam);
    }

    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser'))?.ISSESSION;
    }

    //const s = encodeURIComponent(JSON.stringify(params));
    const s = JSON.stringify(params);

    //const hP = new HttpParams().set('Payload', s);

    return this.http.post<any[]>(this.config.getDbUrl('') + '/execute.icjson', `${s}`, {
      headers: new HttpHeaders({
        // ISSESSION: this.issession ? this.issession : 'createsession',
      }).set('Content-Type', 'application/json'),
      //withCredentials: this.credentials,
    });
  }

  writeArrayActionsData(data?: any): Observable<any[]> {
    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    }
    const s = JSON.stringify(data);
    return this.http.post<any[]>(this.config.getDbUrl('') + '/write.icjson', s, {
      headers: new HttpHeaders({
        // ISSESSION: this.issession,
      }).set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    });
  }

  writeData(remotesetname: any, extraparam: Object, data?: any, usePayload = true): Observable<any[]> {
    let params = { name: remotesetname, action: 'write' };
    if (extraparam !== null) {
      params = Object.assign({}, params, extraparam);
    }

    //let records = { data: data };
    //params = Object.assign({}, params, records);

    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    }
    if (usePayload) {
      const s = encodeURIComponent(JSON.stringify(params));

      return this.http.post<any[]>(this.config.getDbUrl('') + '/write.icjson', 'Payload=' + s, {
        headers: new HttpHeaders({
          // ISSESSION: this.issession,
        }).set('Content-Type', 'application/json'),
        withCredentials: this.credentials,
      });
    } else {
      const s = JSON.stringify(params);
      return this.http.post<any[]>(this.config.getDbUrl('') + '/write.icjson', s, {
        headers: new HttpHeaders({
          // ISSESSION: this.issession,
        }).set('Content-Type', 'application/json'),
        withCredentials: this.credentials,
      });
    }
  }

  writeData2(remotesetname: any, extraparam: Object, data?: any, usePayload = false): Promise<any[]> {
    let params = { name: remotesetname, action: 'write' };
    if (extraparam !== null) {
      params = Object.assign({}, params, extraparam);
    }

    //let records = { data: data };
    //params = Object.assign({}, params, records);

    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    }
    if (usePayload) {
      const s = encodeURIComponent(JSON.stringify(params));

      return this.http.post<any[]>(this.config.getDbUrl('') + '/write.icjson', 'Payload=' + s, {
        headers: new HttpHeaders({
          // ISSESSION: this.issession,
        }).set('Content-Type', 'application/json'),
        withCredentials: this.credentials,
      }).toPromise();
    } else {
      const s = JSON.stringify(params);
      return this.http.post<any[]>(this.config.getDbUrl('') + '/write.icjson', s, {
        headers: new HttpHeaders({
          // ISSESSION: this.issession,
        }).set('Content-Type', 'application/json'),
        withCredentials: this.credentials,
      }).toPromise();
    }
  }  

  reportparams(remotesetname: any, extraparam: Object, data?: any): Observable<any[]> {
    let params = { name: remotesetname, action: 'write' };
    if (extraparam !== null) {
      params = Object.assign({}, params, extraparam);
    }

    const records = { data: data };
    params = Object.assign({}, params, records);

    if (this.credentials) {
      // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    }

    const s = encodeURIComponent(JSON.stringify(params));

    // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    
    return this.http.post<any[]>(this.config.getDbUrl('') + '/reportparams', 'Payload=' + s, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    });
  }

  reportpreview(params: any, command = 'reportparams'): Observable<any> {
    const s = JSON.stringify(params);

    // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    return this.http.post<any>(this.config.getDbUrl('') + '/' + command, s, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    });
  }

  getreport(params: any, command = 'createreport'): Observable<any> {
    const s = JSON.stringify(params);
    command = command+'?reportcode='+params;
    // this.issession = JSON.parse(localStorage.getItem('currentUser')).ISSESSION;
    return this.http.get<any>(this.config.getDbUrl('') + '/' + command,  {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    });
  }

  getproxy(command :string,params:string): Promise<any[]> {
    const s = JSON.stringify({name:command,cached:params});
    return this.http.post<any>(this.config.getDbUrl('') + '/' + command+'.icjson',s,  {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials: this.credentials,
    }).toPromise();
  }
}
