import { IsAction, IsLoaddata, getdataset } from './isapplication.model';

export interface InitPortalMessage {
  applicationname: string;
  applicationtype: number;
}

export interface IIsAppError {
  level: 'error' | 'warning';
  source: string;
  code: number;
  message: string;
}

export interface LoadFormMessage {
  id: number;
  name: string;
  outlet?: number;
  loadas?: number;
  editable?: number;
  account?: string;
  params?: any;
  serveronline?:boolean
}

export interface RegisterOutletMessage {
  outlet: number;
  name: string;
  defaultview?: number;
  editable?: boolean;
  rendercomponent?: any;
  formbuilder?: any;
}

export interface SetOutletMessage {
  outlet: number;
  id: number;
  name: string;
}

export interface LoadMenuMessage {
  module: string;
  menu: string;
}

export interface LoginMessage {
  company: string;
  username: string;
  password: string;
  email?:string;
  applicationname: string;
  applicationid: number;
  application: string;
  language? : string;
  succes?: any[] | any;
  failed?: string;
}

export interface LoginFailedMessage {
  message:string;
  status:number
}

export interface iMessage {
  name: string;
  id?: number;
  sender?: any;
  responder?: any;
  issessioncode?: string;
  channel?: string;
  time?: string;
  message?: string;
  counter?: number;
  color?: string;
}

export interface ExecuteAction {
  command: string;
  data: any;
  validated?: any;
  result?: any;
  batch?: string;
  usepayload?: boolean;
  ismessage?: iMessage;
  backupresponder?: any;
  offline?: boolean;
  sender?: any;
  fromerror?: boolean;
  updatelocal?: boolean;
  localdata?: any;
  localsync?: any;
  retrycount?: number;
  errorfunction?: any;
}

export class SetDataConnectionId {
  static readonly type = '[SetDataConnectionId]';
  constructor(public payload: { connectionid: number }) {}
}

export class InitPortal {
  static readonly type = '[InitPortal]';
  constructor(public payload: InitPortalMessage) {}
}

export class InitEditor {
  static readonly type = '[InitEditor]';
  constructor(public payload: InitPortalMessage) {}
}

export class LoginAction {
  static readonly type = '[Login]';
  constructor(public payload: LoginMessage) {}
}

export class SendofflineCache {
  static readonly type = '[SendofflineCache]';
  constructor() {}
}

export class UpdateLocalCache {
  static readonly type = '[UpdateLocalCache]';
  constructor(public payload: string) {}
}

export class OnlineStatusAction {
  static readonly type = '[OnlineStatus]';
  constructor(public payload: boolean) {}
}

export class ErrorAsMessage {
  static readonly type = '[ErrorAsMessage]';
  constructor(public payload: boolean) {}
}

export class LoginFailed {
  static readonly type = '[LoginFailed]';
  constructor(public payload: { message: string,status?:number }) {}
}

export class ClearLoadedSet {
  static readonly type = '[ClearLoadedSet]';
  constructor(public payload: string) {}
}

export class LogoutAction {
  static readonly type = '[Logout]';
}

export class SelectAccount {
  static readonly type = '[SelectAccount]';
  constructor(public payload: { account: string; username: string; password: string }) {}
}

export class InitAccount {
  static readonly type = '[InitAccount]';
  constructor(public payload: { account: string; username: string; password: string }) {}
}

export class Execute {
  static readonly type = '[Execute]';
  constructor(public payload: ExecuteAction) {}
}

export class ExecCommand {
  static readonly type = '[ExecCommand]';
  constructor(public payload: { name: string; action: string; account?: number; command?: number }) {}
}

export class ExecuteAndRead {
  static readonly type = '[ExecuteAndRead]';
  constructor(public payload: ExecuteAction) {}
}

export class LocalLoginAction {
  static readonly type = '[LocalLogin]';
  constructor(public payload: any) {}
}

export class InitApplication {
  static readonly type = '[InitApplication]';
  constructor(public payload: LoadFormMessage) {}
}

export class InitApplicationByName {
  static readonly type = '[InitApplicationByName]';
  constructor(public payload: LoadFormMessage) {}
}

export class RegisterOutlet {
  static readonly type = '[RegisterOutlet]';
  constructor(public payload: RegisterOutletMessage) {
    console.log(`%cClass: RegisterOutlet, Function: constructor(): `, 'color: black;');
  }
}

export class SetOutlet {
  static readonly type = '[SetOutlet]';
  constructor(public payload: SetOutletMessage) {}
}

export class SetAction {
  static readonly type = '[SetAction]';
  constructor(public payload: { id: number; path: string; value: any }) {}
}

// export class SetFormGroupOutlet {
//   static readonly type = '[SetFormGroupOutlet]';
//   constructor(public payload: { outlet: number; formid: number }) {}
// }

export class ExecuteAction1 {
  static readonly type = '[ExecuteAction]';
  constructor(public payload: IsAction) {}
}

export class LoadModule {
  static readonly type = '[LoadModule]';
  constructor(public payload: LoadFormMessage) {}
}

export class LoadForm {
  static readonly type = '[LoadForm]';
  constructor(public payload: LoadFormMessage) {}
}

export class LoadColumns {
  static readonly type = '[LoadColumns]';
  constructor(public payload: LoadFormMessage) {}
}

export class LoadMenu {
  static readonly type = '[LoadMenu]';
  constructor(public payload: LoadMenuMessage) {}
}

export class LoadPeriodMenu {
  static readonly type = '[LoadPeriodForm]';
  constructor(public payload: string) {}
}

export class LogState {
  static readonly type = '[LogState]';
}

export class LoggingonServer {
  static readonly type = '[LoggingonServer]';
  constructor(public payload: string) {}
}

export class LoadData {
  static readonly type = '[LoadData]';
  constructor(public payload: IsLoaddata) {}
}

export class LoadDataTree {
  static readonly type = '[LoadDataTree]';
  constructor(public payload: IsLoaddata) {}
}

export class FetchData {
  static readonly type = '[FetchData]';
  constructor(public payload: string) {}
}

export class SelectTreeNode {
  static readonly type = '[SelectNode]';
  constructor(public payload: IsAction) {}
}

export class SetConfig {
  static readonly type = '[SetConfig]';
  constructor(public payload: any) {}
}

export class DragEnded {
  static readonly type = '[DragEnded]';
  constructor(public payload: { newsizes: number[]; indices: number[]; outletid: number; mode: boolean }) {}
}

export class GetMaxComponentNumber {
  static readonly type = '[GetMaxComponentNumber]';
}

export class SaveCurrentEditTree {
  static readonly type = '[SaveCurrentEditTree]';
  // constructor(public payload:number){}
}

export class GetEventListners {
  static readonly type = '[NewForm]';
}

export class RunQuery {
  static readonly type = '[RunQuery]';
  constructor(
    public payload: {
      dsname: string;
      sql: string;
      params: any;
      definition: number;
      fixparams?: any;
      account?: string;
      reducer?: any;
      ondataLoaded?: any;
      refresh?:boolean;
      refreshkey?:string;
      component?:any;
    }
  ) {}
}

export class RunScript {
  static readonly type = '[RunScript]';
  constructor(
    public payload: {
      dsname: string;
      sql: string;
      params: any;
      definition: number;
      fixparams?: any;
      account?: string;
      reducer?: any;
      ondataLoaded?: any;
    }
  ) {}
}

export class ClearDSChildCache {
  static readonly type = '[ClearDSChildCache]';
  constructor(public payload: { dsname: string; field: string; items: any[] }) {}
}

// export class CopyForm {
//   static readonly type = '[CopyForm]';
//   constructor(public payload: number) {}
// }

export class LoadDataset {
  static readonly type = '[LoadDataset]';
  constructor(
    public payload: {
      dsname: string;
      params: any;
      master?: any;
      definition?: number;
      fixparams?: any;
      account?: string;
      role?: string;
      reducer?: any;
      alias?: string;
      storeoffline?: boolean;
      checklocalfirst?: boolean;
      reuse?: boolean;
      ondataLoaded?: any;
      component?:any;
    }
  ) {}
}

export class LoadTreeDataset {
  static readonly type = '[LoadTreeDataset]';
  constructor(
    public payload: {
      dsname: string;
      params: any;
      master?: any;
      definition?: number;
      fixparams?: any;
      account?: string;
      role?: string;
      reducer?: any;
      alias?: string;
      storeoffline?: boolean;
      checklocalfirst?: boolean;
      reuse?: boolean;
      ondataLoaded?: any;
      component?:any;
    }
  ) {}
}

export class LoadDatasets {
  static readonly type = '[LoadDatasets]';
  constructor(
    public payload: {
      actions: Array<getdataset>;
      account?: string;
    }
  ) {}
}

export class SetDataset {
  static readonly type = '[SetDataset]';
  constructor(public payload: { dsname: string; dataset: any[] }) {}
}

export class AppendDataSet {
  static readonly type = '[AppendDataSet]';

  constructor(public payload: { dsname: string; data: any | any[] }) {}
}

export class UnshiftDataSet {
  static readonly type = '[UnshiftDataSet]';

  constructor(public payload: { dsname: string; data: any | any[] }) {}
}

export class MutateStateByFunction {
  static readonly type = '[MutateStateByFunction]';

  constructor(public fc: Function) {}
}

export class AddRecord {
  static readonly type = '[AddRecord]';
  constructor(public payload: { dsname: string; data: any; index?: number }) {}
}

export class UpdateRecord {
  static readonly type = '[UpdateRecord]';
  constructor(public payload: { dsname: string; keyfield: string; key: any; data: any; index?: number }) {}
}

export class DeleteRecord {
  static readonly type = '[DelRecord]';
  constructor(public payload: { dsname: string; keyfield: string; key: any; data?: any; index?: number }) {}
}

export class RemoveDataset {
  static readonly type = '[RemoveDataset]';
  constructor(public payload: { dsname: string }) {}
}

export class ExecQry {
  static readonly type = '[ExecQry]';
  constructor(public account: string, public payload: any) {}
}
export class AppError {
  static readonly type = '[AppError]';
  constructor(public payload: IIsAppError) {}
}

export class EnumToDataset {
  static readonly type = '[EnumToDataset]';
  constructor(public payload: { group: string; data: any }) {}
}

export class IsMessage {
  static readonly type = '[iMessage]';
  constructor(public payload: iMessage) {}
}

export class IsMessageHandled {
  static readonly type = '[IsMessageHandled]';
  constructor(public payload: iMessage) {}
}

export class PreviewReport {
  static readonly type = '[PreviewReport]';
  constructor(public payload: any) {}
}

export class StoreInOfflineCache {
  static readonly type = '[StoreInOfflineCache]';
  constructor(public typeAction: string, public payload: any) {}
}
