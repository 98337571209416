import {
  Compiler,
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  NgModuleRef,
  Type,
} from '@angular/core';
import {
  KnownModule,
  WidgetsRegistryService,
} from '@ic-builder/widgets-registry';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class WidgetsLoaderService {
  #modulesMap = new Map<string, Promise<Type<unknown>>>();
  #modulesRefs = new Map<string, NgModuleRef<unknown>>();
  #widgetsFactories = new Map<string, [ComponentFactory<unknown>, Injector]>();

  constructor(
    private registry: WidgetsRegistryService,
    private compiler: Compiler,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private store:Store
  ) {}

  // Implementation of exhaustive type checking function;
  private static throwExhaustiveCheck(o: never, what: string = ''): never {
    throw new Error(`Unknown${what ? ` ${what}` : ''}: "${o}"`);
  }

  private async loadModule(moduleName: KnownModule): Promise<Type<unknown>> {
    // console.trace(
    //   `%cClass: WidgetsLoaderService, Function: loadModule(this.appRef): `,
    //   'color: black;',
    //   this.resolver,
    //   this.injector
    // );
    if (this.#modulesMap.has(moduleName)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.#modulesMap.get(moduleName)!;
    }
    let lazyModulePromise: Promise<Type<unknown>>;
    switch (moduleName) {
      //case 'IseditorModule':
      //  lazyModulePromise = import('@ic-builder/iseditor').then((m) => m.IseditorModule) as Promise<Type<unknown>>;
      //  break;
      case 'IsdocviewerModule':
        lazyModulePromise = import('@ic-builder/isdocviewer').then(
          (m) => m.IsdocviewerModule
        ) as Promise<Type<unknown>>;
        break;
      // case 'IsChartsModule': {
      //   lazyModulePromise = import('@ic-builder/is-charts').then((m) => m.IsChartsModule) as Promise<Type<unknown>>;
      //   break;
      // }
      //case 'IscompModule': {
      //  lazyModulePromise = import('@ic-builder/iscomp').then((m) => m.IscompModule) as Promise<Type<unknown>>;
      //  break;
      // }
      case 'IsFormBuilderModule': {
        lazyModulePromise = import('@ic-builder/is-form-builder').then(
          (m) => m.IsFormBuilderModule
        ) as Promise<Type<unknown>>;
        break;
      }
      case 'FastreportModule': {
        lazyModulePromise = import('@ic-builder/fastreport').then(
          (m) => m.FastreportModule
        ) as Promise<Type<unknown>>;
        break;
      }
      case 'IsWebcamModule': {
        lazyModulePromise = import('@ic-builder/webcam').then(
          (m) => m.IsWebcamModule
        ) as Promise<Type<unknown>>;
        break;
      }
      case 'IswordModule': {
        lazyModulePromise = import('@ic-builder/isword').then(
          (m) => m.IswordModule
        ) as Promise<Type<unknown>>;
        break;
      }      
      case 'IsexcelModule': {
        lazyModulePromise = import('@ic-builder/isexcel').then(
          (m) => m.IsexcelModule
        ) as Promise<Type<unknown>>;
        break;
      }       
      case 'IstensorModule': {
         lazyModulePromise = import('@ic-builder/istensor').then(
           (m) => m.IstensorModule
         ) as Promise<Type<unknown>>;
         break;
      }            
      case 'QrcodeModule': {
        lazyModulePromise = import('@ic-builder/qrcode').then((m) => m.QrcodeModule) as Promise<Type<unknown>>;
        break;
      }
      case 'SvgobjectsModule': {
        lazyModulePromise = import('@ic-builder/svgobjects').then((m) => m.SvgobjectsModule) as Promise<Type<unknown>>;
        break;
      }      
      case 'HtmleditorModule': {
        lazyModulePromise = import('@ic-builder/htmleditor').then(
          (m) => m.HtmleditorModule
        ) as Promise<Type<unknown>>;
        break;
      }

      //case 'IsMonacoEditorModule':
      //  lazyModulePromise = import('@ic-builder/is-monaco-editor').then((m) => m.IsMonacoEditorModule) as Promise<
      //    Type<unknown>
      //  >;
      //  break;
      default: {
        WidgetsLoaderService.throwExhaustiveCheck(moduleName, 'module name');
      }
    }

    if (lazyModulePromise) {
      this.#modulesMap.set(moduleName, lazyModulePromise);
    }
    return (
      this.#modulesMap.get(moduleName) ??
      (await Promise.reject(new Error(`Unknown module "${moduleName}"`)))
    );
  }

  private async getModuleRef(moduleName: KnownModule) {
    let moduleRef = this.#modulesRefs.get(moduleName);
    if (moduleRef) {
      return Promise.resolve(moduleRef);
    }
    const moduleFactory = await this.compiler.compileModuleAsync(
      await this.loadModule(moduleName)
    );
    moduleRef = moduleFactory.create(this.injector);
    this.#modulesRefs.set(moduleName, moduleRef);
    return moduleRef;
  }

  private async getWidgetFactoryWithInjector(
    name: string
  ): Promise<[ComponentFactory<unknown>, Injector]> {
    let factoryWithInjector = this.#widgetsFactories.get(name);
    if (factoryWithInjector) {
      return Promise.resolve(factoryWithInjector);
    }
    const registryWidgetInfo = await this.getWidgetInfo(name);
    const [moduleName, componentType] = registryWidgetInfo;
    const moduleRef = await this.getModuleRef(moduleName);
    const componentFactory =
      moduleRef.componentFactoryResolver.resolveComponentFactory(componentType);
    factoryWithInjector = [componentFactory, moduleRef.injector];
    this.#widgetsFactories.set(name, factoryWithInjector);
    return Promise.resolve(factoryWithInjector);
  }

  private async getWidgetInfo(
    name: string
  ): Promise<[KnownModule, Type<unknown>]> {
    const widgetInfo =
      this.convertEagerTypeToLazy(this.registry.getEagerWidgetInfo(name)) ??
      this.registry.getLazyWidgetInfo(name);
    if (!widgetInfo) {
      const widgetModuleName = this.registry.getLazyRegistry().get(name);
      if (!widgetModuleName) {
        return Promise.reject(
          new Error(`Widget "${name}" was not registered.`)
        );
      }
      await this.getModuleRef(widgetModuleName);
      return (
        this.registry.getLazyWidgetInfo(name) ??
        (await Promise.reject(
          new Error(`Widget "${name}" was not loaded (${widgetModuleName}).`)
        ))
      );
    }
    return Promise.resolve(widgetInfo);
  }

  private convertEagerTypeToLazy(
    eagerConfig: [Type<unknown>, Type<unknown>] | null
  ): [KnownModule, Type<unknown>] | null {
    if (!eagerConfig) {
      return null;
    }
    const [module, component] = eagerConfig;
    if (!this.#modulesMap.has(module.name)) {
      this.#modulesMap.set(module.name, Promise.resolve(module));
      const modRef = this.registry.getEagerModuleRef(module.name);
      if (modRef) {
        this.#modulesRefs.set(module.name, modRef);
      }
    }
    return [module.name as KnownModule, component];
  }

  public async getNewComponentInstance(name: string) {
    //console.log('loadComponent : ', name);
    const [componentFactory, injector] =
      await this.getWidgetFactoryWithInjector(name);
    return componentFactory.create(injector);
  }
}
